.qrcode {
	width: 150px;
	margin-left: 0px;
	height: auto;
	animation: fadeInUp 2s 2s forwards;
	opacity: 0;
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translateY(50px);
	}

	to {
		opacity: 0.8;
		transform: translateY(0);
	}
}

.map-wrap {
	background: white;
	float: right;
	width: 53%;
	height: 115%;
	margin-top: -3%;
}

.leaflet-container {
	position: relative;
	width: 100%;
	height: 100%;
	opacity: 0;
	animation: backInRight 1s 1.2s;
	animation-fill-mode: forwards;
}

.info-map {
	position: absolute;
	background: gold;
	top: 50px;
	right: 40%;
	z-index: 999999;
	width: 267px;
	padding: 20px;
	color: #1f5659;
	font-family: 'Coolvetica';
	font-size: 17px;
	font-weight: 300;
	opacity: 0;
	animation: fadeIn 1s 1.5s;
	animation-fill-mode: forwards;

	a {
		color: #1f5659;
		/* Change the color as needed */
		font-size: 16px;
		/* Adjust font size */
		font-family: "Coolvetica";
		/* Adjust font family */
		position: relative;
		font-weight: 400;
		/* Adjust font weight */
		text-decoration: none;
		/* Remove underline */
		display: inline-block;
		/* Ensure inline-block display */
	}

	a:hover {
		color: white;
		/* Change color on hover */
	}

	span {
		font-size: 16px;

		span {
			color: #ffd700;
		}
	}
}

@media screen and (max-width: 768px) {

	/* Stretch the h1 text when logo is hidden */
	.qrcode {
		display: none;
	}

	.info-map {
		right: 0;
	}

	.info-map a {
		pointer-events: none;
		/* Disable pointer events for the <a> elements */
	}

	.leaflet-container {
		position: fixed;
		bottom: 20px;
		left: 15%;
		top: auto;
		height: 35%;
		width: 85%;
	}

	.map-wrap {
		position: fixed;
		bottom: 20px;
		left: 15%;
		width: 85%;
		height: 35%;
	}
}